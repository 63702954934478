import { bindable } from 'aurelia-framework';
import './box.scss';
import { autoinject } from 'aurelia-framework';

@autoinject
export class Box {
    @bindable color: Color;
}

enum Color {
    Gray = 'gray',
    Blue = 'blue',
    Green = 'green',
}
