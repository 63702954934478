import { ReactiveControllerHost } from 'lit';
import { StorageHandler } from '../storage-handler';
import '../pli/pli-icon';
import { SortableTableController } from './sortable-table-controller';

export const availableSortModes = ['asc', 'desc'] as const;
export type SortOrder = (typeof availableSortModes)[number];

export type TableHeaderItem<T> = {
    title: string;
    field?: Keyed<T>;
};
export type SortableTableOptions<T> = {
    config: T;
    sortOrderKey: string;
};
export type Keyed<T> = T[keyof T];

export type TableConfig<K extends string | number | symbol, V> = Record<K, V>;
export interface SortableTable<T> {
    controller: SortableTableController<T>;
}

export class StorageOrderController {
    private _sortOrders = [...availableSortModes];
    private _field: string | null = null;
    private _order: SortOrder = this._sortOrders[0];
    private host: ReactiveControllerHost;
    private _sortOrderKey: string;

    constructor(host: ReactiveControllerHost, sortOrderKey: string) {
        this.host = host;
        host.addController(this);
        this._sortOrderKey = sortOrderKey;
    }

    get sortField(): string {
        return this._field;
    }

    set sortField(value: string) {
        this._field = value;
        this.host.requestUpdate();
    }

    get sortOrder(): SortOrder {
        return this._order;
    }

    set sortOrder(value: SortOrder) {
        this._order = value;
        this.host.requestUpdate();
    }

    private _setOrderFromStorage() {
        const storedOrderItem = StorageHandler.getFromStorage(this._sortOrderKey);

        if (storedOrderItem !== null) {
            this._order = storedOrderItem.sortOrder as SortOrder;
            this._field = storedOrderItem.field;
        }
    }

    hostConnected() {
        this._setOrderFromStorage();
    }

    hostDisconnected() {}
}
