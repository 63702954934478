import { ContextProvider, createContext } from '@lit/context';
import { HttpClient } from 'aurelia-fetch-client';
import { ReportFilingApi, createReportFilingApi } from 'api/report-filing-api';

export { HttpClient };
export { ReportFilingApi };

export const reportFilingApiContext = createContext<ReportFilingApi>('report-filing-api');
export const createReportFilingApiProvider = (httpClient: HttpClient) =>
    new ContextProvider(document.body, {
        context: reportFilingApiContext,
        initialValue: createReportFilingApi(httpClient),
    });
